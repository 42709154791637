let formID = '';

$(document).ready(function () {

    //Validierungsfunktionen - bei Bedarf erg#nzen
    const validators = {

        required: function (element, result) {
            if (element.val().length < 2) {
                result.message = "Plichtfeld bitte ausfüllen.";
                result.passed = false
                return {
                    result
                };
            } else {
                return {
                    result
                };
            }
        },

        email: function (element, result) {
            let atSign = element.val().indexOf("@");
            let dotSign = element.val().lastIndexOf(".");
            let hasAtSign = atSign != -1;
            let hasDotSign = dotSign != -1;
            let atBeforeDot = atSign < dotSign;
            if (
                element.val().length < 5 ||
                !hasAtSign ||
                !hasDotSign ||
                !atBeforeDot
            ) {
                result.message = "Eingabe ist keine E-Mail-Adresse.";
                result.passed = false
                return {
                    result
                };
            } else {
                return {
                    result
                };
            }
        },

        number: function (element) {
            let telRegEx = /^[\d \(\)+\-.]*$/;
            let tel = element.val();
            if (!telRegEx.test(tel)) {
                return {
                    passed: false,
                    message: "Eingabe darf nur Nummern enthalten",
                };
            } else {
                return {
                    passed: true,
                };
            }
        },

        tel: function (element) {
            let telRegEx = /^[\d \(\)+\-.]*$/;
            let tel = element.val();
            if (!telRegEx.test(tel)) {
                return {
                    passed: false,
                    message: "Eingabe ist keine Telefonnummer.",
                };
            } else {
                return {
                    passed: true,
                };
            }
        },

        dsgvo: function (element) {
            if (!element[0].checked) {
                return {
                    passed: false,
                    message: "Datenschutzerklärung bitte zustimmen.",
                };
            } else {
                return {
                    passed: true,
                };
            }
        },

        checkbox: function (element) {
            if (!element[0].checked) {
                return {
                    passed: false,
                    message: "Datenschutzerklärung bitte zustimmen.",
                };
            } else {
                return {
                    passed: true,
                };
            }
        }
    };


    // Validierung starten bei Submit
    $('.validate-form').submit(function () { 
        formID = $(this).attr("id"); // Formular ID speichern
        $('#' + formID).addClass('validate-touched') // wird jetzt bei Change validiert
        $('#' + formID).find('.validate-input').each(function () {
            let element = $(this)
            validateInput(element)
        })
    })

    // Validierung starten bei Change (falls touched)
    $('.validate-input').change(function () {
        formID = $(this).attr("id"); // Formular ID speichern
        $('#' + formID).removeClass('validation-input-error')
        const errorID = $(this).data('errorid');
        $('#' + errorID).hide();

        if ($('.validate-form').hasClass('validate-touched')) //falls Form schon einmal versucht wurde zu schicken, dann beim Change validieren
        {
            validateInput($(this))
        }

        if (!$('.validate-form').find('.validation-input-error').length > 0) { // Falls keine Fehler mehr vorhanden sind, globalen Error ausblenden
            $('#' + formID + ' .validation-notification-submit').hide();
        }
    })

    function validateInput(element) {
        const valArray = element.data('validation').split(" ");
        const errorID = element.data('errorid');
        const domElement = element;
        const globalErrorMessage = $('#' + formID + ' .validation-notification-submit')

        let result = {
            passed: true,
            message: 'Default Message'
        }
        valArray.forEach(function (validator) {
            const validationFunction = validators[validator]

            result = Object.assign(result, validationFunction(domElement, result))
        })


        if (!result.passed) { //Fehler 

            $('#' + formID + ' #' + errorID).text(result.message);
            $('#' + formID + ' #' + errorID).show();
            domElement.addClass('validation-input-error');
            globalErrorMessage.show();
            event.preventDefault();
        } else { // Kein Fehler
            $('#' + formID + ' #' + errorID).hide();
            domElement.removeClass('validation-input-error');
        }
    }

}); // ENDE DOCUMENT READY