// -----> Settings /// DIESES SCRIPT FUNKTIONIERT NUR MIT DEN AKTUELLEN GA4 PROPERTIES IM BROWSER HEAD MUSS AUSERDEM NOCH FOLGENDER CODE EINGEFÜGT SEIN:
/*
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'GOOGLE_TAG_ID');
</script>

*/
const GOOGLE_TAG_ID = "G-XXXXXX"; // GTAG ID (MESS-ID)
const COOKIE_EXPIRATION_DAYS = 7 // Tage, nach denen der Cookie abläuft

// -----> Prüfen. ob Cookies im verwendeten Browser erlaubt sind
var cookieEnabled = navigator.cookieEnabled;

$(document).ready(function () {

    // onload Funktionen
    if (cookieEnabled) {
        getLayerState();
        getCheckboxState();
    } else {
        disableCookieSettings();
    }

    // --------------------> Events <--------------------

    // -----> Accept Cookies
    $(".cookie-layer__button-yes").click(function () {
        jQuery(".cookie-layer").hide("fast");
        jQuery(".cookie-layer__overlay").removeClass('cookie-layer__overlay--active');
        setCookie("cookieOptin", "in", COOKIE_EXPIRATION_DAYS);
        loadGAonConsent();
    });


    // -----> Reject Cookies
    $(".cookie-layer__button-no").click(function () {
        jQuery(".cookie-layer").hide("slow");
        setCookie("cookieOptin", "out", COOKIE_EXPIRATION_DAYS)
    });


    // -----> Change Cookie-Settings
    $('#change-cookies').find('input').change(function () {
        if ($(this).prop("checked") == true) {
            setCookie("cookieOptin", "in", COOKIE_EXPIRATION_DAYS);
        } else {
            setCookie("cookieOptin", "out", COOKIE_EXPIRATION_DAYS)
        }
    });
});



/**
 * -----> setCookie
 * 
 * setzt einen Cookie.
 * 
 * @param {string} cname Cookie name
 * @param {string} cvalue Cookie value
 * @param {int} exdays Days till expiration
 */
function setCookie (cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires + ";path=/; ";
    getCheckboxState();
}


/**
 * -----> getCookie
 * 
 * Auslesen von bereits gesetzten Cookies.
 * 
 * @param {string} cname Cookie-name
 */
function getCookie (cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1);
        if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    }
    return "";
}


/**
 * -----> loadGoogleAnalytics
 * 
 * Läd das GoogleAnalytics Skript in den head.
 */
function loadGAonConsent() {
    window.ga = window.ga || function () {
        (ga.q = ga.q || []).push(arguments)
    };
    ga.l = +new Date;
    ga('create', GOOGLE_TAG_ID, 'auto');
    ga('set', 'anonymizeIp', true);
    ga('send', 'pageview');
    var gascript = document.createElement("script");
    gascript.async = true;
    gascript.src = "https://www.googletagmanager.com/gtag/js?id=" + GOOGLE_TAG_ID; 
    document.getElementsByTagName("head")[0].appendChild(gascript, document.getElementsByTagName("head")[0]);
}


/**
 * -----> getLayerState
 * 
 * Prüft, ob ein cookie gesetzt ist und zeigt/versteckt den Cookie-Layer
 */
function getLayerState() {
    if ((getCookie("cookieOptin") == "in") || (getCookie("cookieOptin") == "out")) {
        $(".cookie-layer").hide();
        $(".cookie-layer__overlay").removeClass('cookie-layer__overlay--active');
    } else {
        $(".cookie-layer").show();
        $(".cookie-layer__overlay").addClass('cookie-layer__overlay--active');
    };
}


/**
 * -----> getCheckboxState
 * 
 * setzt die Checkbox der Cookie-Einstellungen auf den aktuellen Wert
 */
function getCheckboxState() {
    if (getCookie("cookieOptin") == "in") {
        loadGAonConsent();
        $('#change-cookies').find('input').prop('checked', true)
    } else {
        $('#change-cookies').find('input').prop('checked', false)
    };
}


function disableCookieSettings() {
    $('#change-cookies').find('input').attr('disabled', true);
}
