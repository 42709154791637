$(document).ready(function () {
    var $status = $('.lightbox-paginator');
    var $header = $('.lightbox-header');
    var $caption = $('.lightbox-caption');
    $('.galerie').slickLightbox({
        itemSelector: '> .galerie__item > a',
        background: '#141414',
        caption: 'caption',
        layouts: {
            closeButton: '<div style="position: absolute; width: 36px; height: 36px; top: 24px; right: 24px; object-fit: contain; background: url(./ressources/media/icons/plus_white.svg); transform: rotate(45deg); cursor: pointer"></div>',
        }
    });


    $(document).on('init reInit afterChange', '.slick-lightbox .slick-slider', function (slick) {
        //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
        var i = $(slick)[0].currentSlide + 1;
        $status.text(i + '/' + slick.slideCount);
        var caption = $(`.gallery-item`).eq(i - 1).data('my-caption')
        if (caption == undefined) {
            $caption.hide()
        }
        else {
            $caption.show()
            $caption.text(caption);
        }

    });

    $('.gallery-item').each(function (index, item) {
        $(item).append(`<div class="gallery-item__counter">${index + 1}/${$('.gallery-item').length}</div>`);
    })



})
