$(document).ready(function () {

    $('.read-more-button').on('click', function () {
        let id = $(this).data('read-more');
        let oldText = $(this).text();
        $(this).text($(this).data('text'));
        $(this).data('text', oldText);
        $('#read-more-' + id).slideToggle();
    });

});
