$(document).ready(function () {
  console.log("script1.js");
  /**
   * Navigation anpassen wenn gescrollt wird
   */
  $(document).on("scroll", function () {
    var scrollPos = $(document).scrollTop();
    if (scrollPos > 5 && !$("body").hasClass(".no-body-scrolling")) {
      $(".main-navigation").addClass("main-navigation--scrolled");
      $(".logo--light").hide();
      $(".logo--dark").show();
    } else {
      $(".main-navigation").removeClass("main-navigation--scrolled");
      $(".logo--light").show();
      $(".logo--dark").hide();
    }
  });

  $(".main-navigation__mobile").on("click", function () {
    if ($(this).hasClass("main-navigation__mobile--close")) {
      $(this).removeClass("main-navigation__mobile--close");
      $(this).addClass("main-navigation__mobile--open");
      $(".main-navigation").addClass("main-navigation--open");
      $("body").addClass("no-body-scrolling");
      $(".main-navigation").removeClass("main-navigation--scrolled");
      $(".logo--light").show();
      $(".logo--dark").hide();
    } else {
      $(this).removeClass("main-navigation__mobile--open");
      $(this).addClass("main-navigation__mobile--close");
      $(".main-navigation").removeClass("main-navigation--open");
      $("body").removeClass("no-body-scrolling");
      $(".main-navigation").addClass("main-navigation--scrolled");
      $(".logo--light").hide();
      $(".logo--dark").show();
    }
  });

  /**
   * Akkordeon
   */
  $(".akkordeon__button").on("click", function () {
    if ($(this).hasClass("akkordeon__button--open")) {
      $(this).removeClass("akkordeon__button--open");
      $(this).parent().next(".akkordeon__text").hide();
    } else {
      $(this).addClass("akkordeon__button--open");
      $(this).parent().next(".akkordeon__text").show();
    }
  });

  /**
   * Comic
   */
  $(".comic-button").on("click", function () {
    var text = $(this).data("comic-text");
    var direction = $(this).data("comic-direction");
    console.log(direction);
    if ($(this).parent().hasClass("comic__cta--opened")) {
      $(".comic__cta--opened").removeClass("comic__cta--opened");
      $(".comic--open--left").removeClass("comic--open--left");
      $(".comic--open--right").removeClass("comic--open--right");
      $(".comic__text--open").removeClass("comic__text--open");
      $(".comic__text").removeClass("slide-left");
      $(".comic__text").removeClass("slide-right");
    } else {
      $(".comic__cta--opened").removeClass("comic__cta--opened");
      $(".comic__text--open").removeClass("comic__text--open");
      $(".comic--open--left").removeClass("comic--open--left");
      $(".comic--open--right").removeClass("comic--open--right");
      $(".comic__text").removeClass("slide-left");
      $(".comic__text").removeClass("slide-right");
      $(this).parent().addClass("comic__cta--opened");
      $(".comic").addClass("comic--open--" + direction);
      if (direction == "right") {
        $("#" + text).addClass("slide-left");
      } else {
        $("#" + text).addClass("slide-right");
      }
      $("#" + text).addClass("comic__text--open");
    }
  });

  /**
   * Bild-Text-Slider Initialisieren
   */
  $(".bild-text-slider-js").slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    arrows: true,
    cssEase: "ease-in-out",
    speed: 800,
    pauseOnHover: true,
    prevArrow: $(".bild-text-slider__nav-prev"),
    nextArrow: $(".bild-text-slider__nav-next"),
    dots: true,
  });

  /**
   * Text-Slider Initialisieren
   */
  $(".text-slider").slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    arrows: true,
    cssEase: "ease-in-out",
    speed: 800,
    pauseOnHover: true,
    prevArrow: $(".text-slider__nav-prev"),
    nextArrow: $(".text-slider__nav-next"),
    dots: true,
  });

  /**
   * Programm-Slider Initialisieren
   */
  $(".program-slider").slick({
    infinite: false,
    autoplay: false,
    autoplaySpeed: 6000,
    arrows: true,
    cssEase: "ease-in-out",
    speed: 800,
    pauseOnHover: true,
    prevArrow: $(".program-slider__nav-prev"),
    nextArrow: $(".program-slider__nav-next"),
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1260,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 660,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });
});
